<template>
  <div class="tags">
    <el-select
      v-model="tags"
      multiple
      placeholder="请选择"
      clearable
      allow-create
      filterable
      default-first-option
      @change="selectLabel"
      value-key="labelId"
      size="small"
      @visible-change="saveLabel"
      v-if="editLabelsShow"
      :multiple-limit="3"
      ref="labelSelect"
      automatic-dropdown
    >
      <el-option
        v-for="item in labelList"
        :key="item.labelId"
        :label="item.name"
        :value="item"
      >
        <span style="float: left">{{ item.name }}</span>
        <span style="float: right; color: #8492a6; font-size: 13px">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="mini"
            @click.stop="deleteLabel(item)"
          ></el-button>
        </span>
      </el-option>
    </el-select>
    <div
      class="tags-wrap"
      v-if="!editLabelsShow"
    >
      <el-tag
        size="mini"
        closable
        v-for="label in savedTags"
        :key="label.labelId"
        @close="deleteTag(label)"
        >{{ label.name }}</el-tag
      >
    </div>
    <el-button
      type="text"
      icon="el-icon-edit"
      @click.stop="editLabels"
      v-if="!editLabelsShow"
    ></el-button>
    <el-button
      type="text"
      icon="el-icon-delete"
      @click.stop="clearLabel"
      v-if="savedTags && savedTags.length"
    ></el-button>
  </div>
</template>
<script>
import { saveConfigLabel, delLabel, saveAdLabel, delAdLabel } from '../../serve/campaignLabel';
export default {
  props: {
    labelList: {
      type: Array,
      default: () => [],
    },
    level: {
      type: String,
      default: 'campaign',
    },
    info: {
      type: Object,
      default: () => {},
    },
    accountId: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: 0,
    },
    platform: {
      type: String,
      default: 'facebook',
    },
    // tt 必传
    gimpUserId: {
      type: String | Number,
      default: '',
    },
  },
  data() {
    return {
      options: [],
      tags: [],
      editLabelsShow: false,
    };
  },
  computed: {
    key() {
      return this.platform == 'facebook' ? 'ad_label' : 'tags';
    },
    savedTags: {
      get() {
        return this.info.row[this.key] ? JSON.parse(JSON.stringify(this.info.row[this.key])) : [];
      },
      set() {},
    },
  },
  // created() {
  //     this.savedTags = this.info.ad_label?JSON.parse(JSON.stringify(this.info.ad_label)):[];
  // },
  methods: {
    editLabels() {
      this.editLabelsShow = true;
      this.$nextTick(() => {
        this.tags = JSON.parse(JSON.stringify(this.savedTags));
        this.$nextTick(() => {
          this.$refs.labelSelect.focus();
        });
      });
    },
    addLabel(v) {
      if (v.length > 5) {
        this.$message({
          type: 'info',
          message: '标签限制在5个字以内哦',
        });
        this.tags = this.tags.slice(0, this.tags.length - 1);
        return false;
      }
      saveConfigLabel({ name: v, level: this.level, platform: this.platform, gimpUserId: this.gimpUserId }).then(
        (res) => {
          if (res.code == 0) {
            this.$emit('getLabelList');
            // this.tags = this.tags.slice(0,this.tags.length-1)
            // this.tags.push(res.data.name);
            this.$forceUpdate();
          }
        },
      );
    },
    selectLabel(v) {
      if (!v.length) {
        this.clearLabel();
      } else {
        let val = v[v.length - 1];
        if (!val.labelId) {
          this.addLabel(val);
        }
      }
    },
    deleteLabel(v) {
      // console.log(v);
      // let formData = new FormData();
      // formData.append('labelId', v.labelId)
      // delLabel({ labelId: v.labelId, platform: this.platform }).then(res => {
      //     if (res.code == 0) {
      //         this.$emit('getLabelList');
      //         this.tags = this.tags.filter(item => item.labelId !== v.labelId);
      //         this.tags = JSON.parse(JSON.stringify(this.tags));
      //         this.savedTags =  this.savedTags.filter(item => item.labelId !== v.labelId);
      //     }
      // })
      this.$confirm('删除标签，所有与之相关的广告与标签关联关系也会随之删除', '提示', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let formData = new FormData();
          formData.append('labelId', v.labelId);
          delLabel({ labelId: v.labelId, platform: this.platform }).then((res) => {
            if (res.code == 0) {
              this.$emit('getLabelList', v.labelId);
              this.info.row[this.key] = this.savedTags.filter((item) => item.labelId !== v.labelId);
              this.$emit('scope-row-changed', this.info);
            }
          });
        })
        .catch(() => {});
    },
    saveLabel(v) {
      console.log(this.tags);
      if (this.tags.length) {
        let flag = false;
        let labels = this.tags.map((k) => {
          let item = this.labelList.find((m) => m.name == (k.name || k));
          if (!item) flag = true;
          return {
            ...item,
            level: this.level,
            platform: this.platform,
            adId: this.info.row.id,
            adAccountId: this.accountId,
            testParam: 'test', // 无效参数，可以随便删
          };
        });
        // 下拉隐藏，调保存接口
        console.log(v);
        if (!v) {
          if (labels.length && !flag) {
            saveAdLabel({ list: labels }).then((res) => {
              console.log(res);
              if (res.code == 0) {
                this.$message({
                  type: 'success',
                  message: '保存成功',
                });
                this.editLabelsShow = false;
                // this.savedTags = JSON.parse(JSON.stringify(labels));
                console.log(this.info);
                this.info.row[this.key] = JSON.parse(JSON.stringify(labels));
                this.$emit('scope-row-changed', this.info);
              }
            });
          }
        } else {
          // this.editLabelsShow = false;
        }
      }
      if (!this.tags.length && !v) {
        if (this.info.row[this.key] && this.info.row[this.key].length) {
          this.clearLabel();
        } else {
          this.editLabelsShow = false;
        }
      }
    },
    // 删除广告标签的关联关系
    deleteTag(label) {
      console.log(label);
      delAdLabel({ adLabelIds: [label.adLabelId] }).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: '删除成功',
          });
          // this.savedTags = this.savedTags.filter(v => v.labelId != label.labelId)
          this.info.row[this.key] = this.savedTags.filter((v) => v.labelId != label.labelId);
          this.$emit('scope-row-changed', this.info);
        }
      });
    },
    // 清除当前广告的所有关系标签
    clearLabel() {
      this.$confirm('该条数据的标签将全部被清除', '提示', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let ids = this.savedTags.map((k) => k.adLabelId);
          delAdLabel({ adLabelIds: ids, adId: this.info.id }).then((res) => {
            if (res.code == 0) {
              this.$message({
                type: 'success',
                message: '删除成功',
              });
              this.info.row[this.key] = [];
              this.$emit('scope-row-changed', this.info);
              this.editLabelsShow = false;
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.tags {
  display: flex;
  align-items: center;
}

.tags-wrap {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .el-tag {
    margin: 2px;
  }
}
</style>
